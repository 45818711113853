import { match } from 'ts-pattern';
import { DataModelSchema } from '../types/datamodel/schema';
import { TimeSeriesQuery } from './time-series';
import { dateFormat } from '../utils/date-format';
import { sqlForFilters } from './filters-sql';
import { sqlForMetrics } from './metrics-sql';
import { indent } from './indent';
import { fieldExists, findDateField } from './schema.ts';

export function sqlForTimeSeriesQuery(
    schema: DataModelSchema,
    sourceTable: string,
    query: TimeSeriesQuery
) {
    const granularity = match(query.granularity)
        .with('day', 'month', 'quarter', 'week', granularity => granularity)
        .with(undefined, () => 'millenium')
        .exhaustive();

    const start = dateFormat(query.dateRange.start);
    const end = dateFormat(query.dateRange.end);

    const dateField = findDateField(schema, query.dateField);

    return `
WITH
  y AS (
    SELECT 
      DATE_TRUNC('${granularity}', ${schema.dateField}) AS x,
${indent(sqlForMetrics(schema, query.metrics), 6)}
    FROM ${sourceTable}
    WHERE ${dateField} BETWEEN date '${start}' AND date '${end}'
${indent(sqlForFilters(schema, query.filters), 6)}
    GROUP BY x
  ),
  
  x AS (
    SELECT
      DATE_TRUNC('${granularity}', d) AS x,
      CAST(MIN(d) AS DATE) AS start,
      CAST(MAX(d) AS DATE) AS end,
    FROM (SELECT UNNEST(GENERATE_SERIES(date '${start}', date '${end}', INTERVAL 1 DAY)) AS d)
    GROUP BY x
  )

SELECT
  x.start::string as start,
  x.end::string as end,
${query.metrics
    .filter(fieldExists(schema))
    .map(m => `  IFNULL(y.${m}, 0) AS ${m},`)
    .join('\n')}
FROM x
LEFT JOIN y USING (x)
ORDER BY start
`;
}
