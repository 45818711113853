export interface DataModelField {
    id: string;
    sql: string;
    name: string;
    shortName: string | null;
    category: string;
    format: FormatType;
    features?: Feature[];
    agg?: Aggregation | null;
    dataSourceType: string;
    children?: DataModelField[];
}

export type WeightedAverageDataModelField = DataModelField & {
    agg: WeightedAvg;
};

export function isWeightedAvgDataModelField(
    field: DataModelField
): field is WeightedAverageDataModelField {
    return field?.agg?.type === 'weighted_avg';
}

export interface DataModelSchema {
    fields: DataModelField[];
    dateField: string;
    entityName?: string;
}

type Aggregation = CountDistinct | Sum | WeightedAvg;

type Sum = { type: 'sum' };
type WeightedAvg = {
    type: 'weighted_avg';
    numerator: string;
    denominator: string;
};
type CountDistinct = {
    type: 'count_distinct';
    field: string;
};

export type Feature =
    | 'baseline'
    | 'dashboard_dimension'
    | 'dashboard_measure'
    | 'explorer_default_field'
    | 'negative_measure'
    | 'explorer_master_detail'
    | 'explorer_master_detail_root'
    | 'conversion'
    | 'hide'
    | 'non_grouped'
    | 'no_set_filter'
    | string;

export type FormatType = DimensionFormat | MetricFormat | MiscFormat;
export type DimensionFormat = (typeof dimensionFormats)[number];
export type MetricFormat = (typeof metricFormats)[number];
export type MiscFormat = (typeof miscFormats)[number];

const dimensionFormats = [
    'boolean',
    'country',
    'date',
    'month',
    'number_text',
    'string',
    'weekday',
    'url',
] as const;

const metricFormats = [
    'currency',
    'currency_rounded',
    'duration',
    'number',
    'number_rounded',
    'percent',
] as const;

const miscFormats = ['url'] as const;

const stringFormats = ['string', 'country', 'url'];

export const isDimension = (f: DataModelField) =>
    isDimensionFormat(f.format) && notHasFeature('hide')(f);
export const isGroupedDimension = (f: DataModelField) =>
    isDimension(f) && notHasFeature('non_grouped')(f) && f.format !== 'url';
export const isNonGroupedDimension = (f: DataModelField) =>
    isDimension(f) && hasFeature('non_grouped')(f);
export const isDateDimension = (f: DataModelField) => f.format === 'date';
export const isMetric = (f: DataModelField) => isMetricFormat(f.format);
export const isConversionMetric = (f: DataModelField) =>
    isMetric(f) && !!f.features?.includes('conversion');
export const shouldCastToFloat = (f: DataModelField) =>
    isMetric(f) && f.agg?.type !== 'count_distinct';

export const hasFeature = (feature: Feature) => (f: DataModelField) =>
    f.features?.includes(feature);
export const isFunnelMetric = hasFeature('funnel');

export const notHasFeature = (feature: Feature) => (f: DataModelField) =>
    !f.features?.includes(feature);

export const isDimensionFormat = (f: FormatType): f is DimensionFormat =>
    (dimensionFormats as unknown as FormatType[]).includes(f);

export const isMetricFormat = (f: FormatType) =>
    (metricFormats as unknown as FormatType[]).includes(f);

export const isStringFormat = (f: FormatType) =>
    (stringFormats as unknown as FormatType[]).includes(f);

export const isNegativeMetric = (f: DataModelField) =>
    hasFeature('negative_measure')(f);

export const isFilterable = (f: DataModelField) =>
    notHasFeature('hide') && f.format !== 'url';
export const isFilterableDimension = (f: DataModelField) =>
    isFilterable(f) &&
    isDimension(f) &&
    // Temporary filter until unnested datasets are fully released.
    f.category != 'UTM';

export const isFilterableMetric = (f: DataModelField) =>
    isFilterable(f) && isMetric(f);

export const isSetFilterDisabled = (f: DataModelField) =>
    isNonGroupedDimension(f) || hasFeature('no_set_filter')(f);

const sortKey = (field: DataModelField) =>
    `${field.category}:${field.shortName ?? field.name}`;

export const sortFields = (a: DataModelField, b: DataModelField) =>
    sortKey(a).localeCompare(sortKey(b));

export const isTargetMetric = (f: DataModelField) =>
    isMetric(f) &&
    !!f.agg?.type &&
    notHasFeature('negative_measure')(f) &&
    notHasFeature('hide')(f);

export const asStoredValue = (f: DataModelField | undefined, value: number) =>
    f?.format === 'percent' ? Math.round(value * 100) / 10000 : value;

export const asRenderedValue = (f: DataModelField | undefined, value: number) =>
    f?.format === 'percent' ? Math.round(value * 10000) / 100 : value;
