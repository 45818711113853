import { Box, Button, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { FormDialog } from '../../components/Dialog/FormDialog';
import { useExplorerViews } from '../../hooks/use-explorer-views';
import { useDatasets } from '../../hooks/use-datasets';
import { useDatasetSchema } from '../../hooks/use-dataset-schema';
import { useActiveWorkspace } from '../../providers/WorkspaceProvider.hooks';
import { ExplorerView } from '../../components/ExplorerGrid/grid/utils/gridView';
import { useActiveUser } from '../../providers/CurrentUserProvider.hooks';
import { newView } from '../../types/explorer';
import { DateFieldSelect } from './DateFieldSelect.tsx';
import { DatasetSelect } from '../../components/DatasetSelect/DatasetSelect.tsx';

interface SelectDatasetPanelProps {
    open: boolean;
    onClose: () => void;
    onCreate: (view: ExplorerView) => void;
}

interface CreateViewProps {
    onCreate: (view: ExplorerView) => void;
}

export const CreateView = ({ onCreate }: CreateViewProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Box>
            <Button size="small" type="button" onClick={() => setIsOpen(true)}>
                New view
            </Button>
            <CreateViewModal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                onCreate={onCreate}
            />
        </Box>
    );
};

const CreateViewModal = ({
    open,
    onClose,
    onCreate,
}: SelectDatasetPanelProps) => {
    const { workspaceId } = useActiveWorkspace();
    const user = useActiveUser();
    const { createView } = useExplorerViews(workspaceId);

    const { isLoading: datasetsLoading } = useDatasets();

    const [title, setTitle] = useState('Untitled');
    const [dataset, setDataset] = useState('campaign_metrics_by_send_date');
    const [dateFieldId, setDateFieldId] = useState<string | null>(null);

    const { data: schema, isLoading: schemaLoading } =
        useDatasetSchema(dataset);

    const dateField =
        schema?.fields.find(f => f.id === dateFieldId) ??
        schema?.fields.find(f => f.id === schema?.dateField);

    const reset = () => {
        setTitle('Untitled');
        setDataset('campaign_metrics_by_send_date');
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    const handleConfirm = () => {
        if (!schema) {
            throw new Error('Schema is unexpectedly undefined');
        }

        const view = newView(
            title,
            dataset,
            schema,
            {
                id: user.memberId,
                name: user.name,
                email: user.email,
            },
            [],
            dateField?.id ?? null
        );
        createView(view);
        onCreate(view);
        handleClose();
    };

    return (
        <FormDialog
            open={open}
            disableRestoreFocus={true}
            title="Create view"
            contentLoading={datasetsLoading}
            content={
                <Stack spacing={2}>
                    <TextField
                        autoFocus={true}
                        size="small"
                        label="Title"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                    />
                    <DatasetSelect
                        allowedDatasets={[]}
                        value={dataset}
                        onChange={e => setDataset(e.target.value)}
                    />

                    {schema && dataset !== 'campaign_metrics_by_event_date' && (
                        <DateFieldSelect
                            dataset={dataset}
                            schema={schema}
                            value={dateField?.id}
                            onChange={e => setDateFieldId(e.target.value)}
                        />
                    )}
                </Stack>
            }
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            confirmButtonText="Save"
            confirmButtonProps={{ disabled: schemaLoading }}
        ></FormDialog>
    );
};
