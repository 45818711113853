import { useDashboardDataset } from '../../../../hooks/use-dashboard-dataset.ts';
import { useLists } from '../../../../hooks/use-lists';
import {
    fixedConversionMetrics,
    WidgetConfig,
} from '../../../../types/dashboard';
import { AutocompleteSortable } from './AutocompleteSortable';
import { CampaignTitle } from './CampaignTitle';
import { MetricsByDimensionBuilder } from './MetricByDimension/MetricsByDimensionBuilder';
import { useConfigOptions } from './useConfigOptions';
import { BoldStyle, FormControlGrid } from './WidgetConfigForm.styled';
import { defaultFilter } from './defaultFilter.tsx';
import { defaultMetricsByDimension } from './MetricByDimension/defaultMetricsByDimension.tsx';
import { DatasetSelect } from '../../../DatasetSelect/DatasetSelect.tsx';
import { useMemo } from 'react';
import { FilterBuilder } from '../../../FilterBuilder/FilterBuilder.tsx';
import { useDatasetSchema } from '../../../../hooks/use-dataset-schema.ts';
import { SchemaFieldSelect } from '../../../SchemaFieldSelect/SchemaFieldSelect.tsx';
import { isGroupedDimension } from '../../../../types/datamodel/schema.ts';
import { useTargets } from '../../../../pages/Targets/useTargets.ts';
import { Box, FormControl, Stack } from '@mui/material';
import { DateFieldSelect } from '../../../../pages/Explorer/DateFieldSelect.tsx';
import { findDateField } from '../../../../queries/schema.ts';

interface WidgetConfigFormProps {
    currentConfig: WidgetConfig;
    onChange: (config: Partial<WidgetConfig>) => void;
}

const allowedDatasets = [
    'campaign_metrics_by_send_date',
    'campaign_metrics_by_event_date',
    'squeezely',
    'ecommerce',
    'workspaces',
    'mailchimp_archive',
];

export const WidgetConfigForm = ({
    currentConfig,
    onChange,
}: WidgetConfigFormProps) => {
    const { data: dataset } = useDashboardDataset(currentConfig.dataset);
    const { data: schema } = useDatasetSchema(currentConfig.dataset);
    const listOptions = useLists();
    const { data: targets = [] } = useTargets();

    const {
        showDataset,
        showDateField,
        showDimension,
        showDimensions,
        showFilterDimensions,
        showFilterLists,
        showMetrics,
        metricsByDimension,
        showTargets,
        showFunnelMetrics,
    } = useConfigOptions(currentConfig, schema);

    const metricOptions = useMemo(
        () => Object.keys(dataset?.metrics || []),
        [dataset?.metrics]
    );

    const funnelMetricOptions = useMemo(
        () => Object.keys(dataset?.funnelMetrics || []),
        [dataset?.funnelMetrics]
    );

    const dimensionOptions = useMemo(
        () => Object.keys(dataset?.dimensions || []),
        [dataset?.dimensions]
    );

    const validMetrics = useMemo(
        () => currentConfig.metrics.filter(m => metricOptions.includes(m)),
        [metricOptions, currentConfig.metrics]
    );

    const validDimensions = useMemo(
        () =>
            currentConfig.dimensions?.filter(d => dimensionOptions.includes(d)),
        [dimensionOptions, currentConfig.dimensions]
    );

    const validGroupBy = useMemo(
        () => currentConfig.groupBy?.filter(d => dimensionOptions.includes(d)),
        [dimensionOptions, currentConfig.groupBy]
    );

    const validFilters = useMemo(
        () =>
            currentConfig.filters?.filter(
                f => !f.member || dimensionOptions.includes(f.member)
            ) || [defaultFilter()],
        [dimensionOptions, currentConfig.filters]
    );

    const validMetricsByDimensions = useMemo(
        () =>
            currentConfig.metricsByDimensions?.filter(
                dm =>
                    (!dm.dimension ||
                        dimensionOptions.includes(dm.dimension)) &&
                    (!dm.metric || metricOptions.includes(dm.metric))
            ) || [defaultMetricsByDimension()],
        [dimensionOptions, metricOptions, currentConfig.metricsByDimensions]
    );

    const validFunnelMetrics = useMemo(
        () =>
            (
                currentConfig.funnelMetrics || [
                    ...fixedConversionMetrics,
                    currentConfig.conversionMetric || 'transactions',
                ]
            )?.filter(fm => funnelMetricOptions.includes(fm)),
        [
            currentConfig.conversionMetric,
            currentConfig.funnelMetrics,
            funnelMetricOptions,
        ]
    );

    const targetOptions = useMemo(() => targets.map(t => t.id), [targets]);
    const validTargets = useMemo(
        () =>
            currentConfig.targets?.filter(target =>
                targetOptions.includes(target)
            ),
        [targetOptions, currentConfig.targets]
    );

    if ((!dataset && currentConfig.dataset !== 'list_members') || !schema) {
        return <></>;
    }

    return (
        <FormControlGrid fullWidth>
            <BoldStyle>Title</BoldStyle>
            <CampaignTitle
                initial={currentConfig.title}
                onChange={newValue => onChange({ title: newValue })}
            />

            {showDataset && (
                <>
                    <BoldStyle>Dataset</BoldStyle>
                    <DatasetSelect
                        value={currentConfig.dataset}
                        allowedDatasets={allowedDatasets}
                        onChange={e => onChange({ dataset: e.target.value })}
                    />
                </>
            )}

            {showDateField && schema && (
                <>
                    <BoldStyle>Period by</BoldStyle>
                    <DateFieldSelect
                        dataset={currentConfig.dataset}
                        schema={schema}
                        value={findDateField(schema, currentConfig.dateField)}
                        onChange={e => onChange({ dateField: e.target.value })}
                    />
                </>
            )}

            {dataset && showMetrics && (
                <>
                    <BoldStyle>Metrics</BoldStyle>
                    <AutocompleteSortable
                        key="config-metrics"
                        label="Selected metrics"
                        disableCloseOnSelect={true}
                        getOptionLabel={option =>
                            dataset.metrics[option]?.label || option
                        }
                        groupBy={option => dataset.metrics[option].category}
                        value={validMetrics}
                        options={metricOptions}
                        onChange={newValue => {
                            onChange({
                                metrics: newValue,
                            });
                        }}
                    />
                </>
            )}

            {dataset && showDimension && (
                <>
                    <BoldStyle>Group by</BoldStyle>
                    <SchemaFieldSelect
                        fullWidth
                        schema={schema}
                        value={currentConfig.dimension || null}
                        includeNone={true}
                        fieldFilter={isGroupedDimension}
                        onChange={newValue => {
                            onChange({ dimension: newValue || undefined });
                        }}
                    />
                </>
            )}

            {dataset && showFunnelMetrics && (
                <>
                    <BoldStyle>Metric</BoldStyle>
                    <AutocompleteSortable
                        key="config-lists"
                        label="Select metrics"
                        disableCloseOnSelect={true}
                        getOptionLabel={option =>
                            dataset.funnelMetrics[option]?.label || option
                        }
                        groupBy={option =>
                            dataset.funnelMetrics[option].category
                        }
                        minNumberOfValues={1}
                        value={validFunnelMetrics}
                        options={funnelMetricOptions}
                        sortOnSelect
                        onChange={newValue => {
                            onChange({ funnelMetrics: newValue });
                        }}
                    />
                </>
            )}

            {dataset && showDimensions && (
                <>
                    <BoldStyle>Dimensions</BoldStyle>
                    <AutocompleteSortable
                        key="config-dimensions"
                        label="Dimensions"
                        disableCloseOnSelect={true}
                        getOptionLabel={option =>
                            dataset.dimensions[option]?.label || option
                        }
                        groupBy={option => dataset.dimensions[option].category}
                        value={validDimensions}
                        options={dimensionOptions}
                        onChange={newValue => {
                            onChange({
                                dimensions: newValue,
                            });
                        }}
                    />
                </>
            )}

            {dataset && showDimensions && (
                <>
                    <BoldStyle>Group by</BoldStyle>
                    <AutocompleteSortable
                        key="config-group-by"
                        label="Group by"
                        disableCloseOnSelect={true}
                        getOptionLabel={option =>
                            dataset.dimensions[option]?.label || option
                        }
                        groupBy={option => dataset.dimensions[option].category}
                        value={validGroupBy}
                        options={dimensionOptions}
                        onChange={newValue => {
                            onChange({
                                groupBy: newValue,
                            });
                        }}
                    />
                </>
            )}

            {dataset && metricsByDimension && (
                <>
                    <BoldStyle
                        sx={
                            (currentConfig.filters || [true]).length > 0
                                ? { alignSelf: 'start', marginTop: '0.625rem' }
                                : {}
                        }
                    >
                        High/Low
                    </BoldStyle>
                    <MetricsByDimensionBuilder
                        dataset={currentConfig.dataset}
                        metricsByDimensions={validMetricsByDimensions}
                        onChange={metricsByDimensions => {
                            onChange({ metricsByDimensions });
                        }}
                    />
                </>
            )}

            {dataset && showFilterDimensions && (
                <>
                    <BoldStyle
                        sx={
                            (currentConfig.filters || [true]).length > 0
                                ? { alignSelf: 'start', marginTop: '0.5rem' }
                                : {}
                        }
                    >
                        Filters
                    </BoldStyle>
                    <FilterBuilder
                        dataset={currentConfig.dataset}
                        filters={validFilters || []}
                        onChange={filters => onChange({ filters })}
                        schema={schema}
                        fieldType={'dimensions'}
                    />
                </>
            )}

            {showFilterLists && (
                <>
                    <BoldStyle>Filter lists</BoldStyle>
                    <AutocompleteSortable
                        key="config-lists"
                        label="Select lists"
                        disableCloseOnSelect={true}
                        getOptionLabel={option => option}
                        value={currentConfig.filteredLists}
                        options={listOptions}
                        onChange={newValue => {
                            onChange({ filteredLists: newValue });
                        }}
                    />
                </>
            )}

            {showTargets && (
                <>
                    <Box pt={1}>
                        <BoldStyle>Filter targets</BoldStyle>
                    </Box>
                    <Stack component={FormControl} justifyContent="center">
                        <AutocompleteSortable
                            key="targets-lists"
                            label="Select target(s)"
                            disableCloseOnSelect={true}
                            getOptionLabel={option =>
                                targets.find(t => t.id === option)?.title ||
                                option
                            }
                            getOptionKey={option => option}
                            value={validTargets}
                            options={targetOptions}
                            onChange={newValue => {
                                onChange({ targets: newValue });
                            }}
                        />
                    </Stack>
                </>
            )}
        </FormControlGrid>
    );
};
