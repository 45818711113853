import { WidgetConfig } from '../../../../types/dashboard';
import {
    DataModelSchema,
    isDateDimension,
} from '../../../../types/datamodel/schema.ts';

export const useConfigOptions = (
    config: WidgetConfig,
    schema?: DataModelSchema
) => {
    const CONFIGURE_METRICS = ['campaign_performance', 'table', 'graph'];
    const CONFIGURE_FUNNEL_METRICS = ['funnel'];
    const FILTER_DIMENSIONS = [
        'campaign_performance',
        'table',
        'funnel',
        'graph',
    ];
    const HAS_DATASET = ['campaign_performance', 'table', 'graph', 'high_low'];
    const HAS_DIMENSIONS = ['table'];
    const FILTER_LISTS = ['subscriber_growth'];

    const METRICS_BY_DIMENSION = ['high_low'];

    return {
        showDataset: HAS_DATASET.includes(config.type),
        showDateField:
            HAS_DATASET.includes(config.type) &&
            (schema?.fields || []).filter(isDateDimension).length > 1 &&
            config.dataset !== 'campaign_metrics_by_event_date',
        showDimension: config.type === 'graph',
        showDimensions: HAS_DIMENSIONS.includes(config.type),
        showMetrics: CONFIGURE_METRICS.includes(config.type),
        showFilterLists: FILTER_LISTS.includes(config.type),
        showFilterDimensions: FILTER_DIMENSIONS.includes(config.type),
        showTargets: config.type === 'target',
        metricsByDimension: METRICS_BY_DIMENSION.includes(config.type),
        showFunnelMetrics: CONFIGURE_FUNNEL_METRICS.includes(config.type),
    };
};
