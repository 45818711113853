import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import {
    Controller,
    FormProvider,
    useForm,
    useFormContext,
} from 'react-hook-form';
import { DatasetSelect } from '../../components/DatasetSelect/DatasetSelect.tsx';
import * as React from 'react';
import { PropsWithChildren } from 'react';
// @ts-expect-error todo
import { zodResolver } from '@hookform/resolvers/zod';
import { Target } from '../../types/targets';
import { z, ZodTypeAny } from 'zod';
import {
    asRenderedValue,
    asStoredValue,
    DataModelSchema,
    isTargetMetric,
} from '../../types/datamodel/schema.ts';
import { SchemaFieldSelect } from '../../components/SchemaFieldSelect/SchemaFieldSelect.tsx';
import {
    datasetQueryKey,
    useDatasetSchema,
} from '../../hooks/use-dataset-schema.ts';
import { Loader } from '../../components/Loader/Loader.tsx';
import { isString } from 'lodash';
import { LoadingButton } from '@mui/lab';
import { FilterBuilder } from '../../components/FilterBuilder/FilterBuilder.tsx';
import {
    BinaryFilter,
    binaryOperators,
    isSetOperator,
} from '../../queries/filters.ts';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { fieldExists } from '../../queries/schema.ts';
import { useTargetMutation } from './useTargetMutation.ts';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDialog } from '../../components/Dialog/useDialog.ts';
import { NumericInputFormatter } from '../../components/InputAdapter/NumericFormat.tsx';
import { TargetCard } from './TargetCard.tsx';
import Typography from '@mui/material/Typography';
import { SeinoDialogTitle } from '../../components/Dialog/SeinoDialog.styled.tsx';
import { eventBus } from '../../event-bus';
import { SeinoErrorBoundary } from '../../components/ErrorPage/SeinoErrorBoundary.tsx';
import { Calculator } from './Calculator/Calculator.tsx';
import { FormatAdornment } from '../../components/Form/FormatAdornment.tsx';
import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';

const allowedDatasets = [
    'campaign_metrics_by_send_date',
    'campaign_metrics_by_event_date',
    'list_members',
    'workspaces',
    'mailchimp_archive',
];

const filterSchema = z
    .object({
        member: z.string(),
        operator: z.enum(binaryOperators),
        values: z.string().min(1).array(),
    })
    .refine(
        ({ operator, values }) => {
            return isSetOperator(operator) || values.length > 0;
        },
        () => ({ path: ['values'] })
    );

const filterConfigSchema = (queryClient: QueryClient, workspaceId: string) =>
    z
        .object({
            dataset: z.string(),
            filters: filterSchema.array(),
        })
        .superRefine(({ dataset, filters }, ctx) => {
            const schema: DataModelSchema | undefined =
                queryClient.getQueryData(datasetQueryKey(workspaceId, dataset));
            const isExistingField =
                (schema && fieldExists(schema)) || (() => false);
            const validateFilterRow =
                (name: string) =>
                ({ member }: BinaryFilter, index: number) => {
                    if (!isExistingField(member)) {
                        ctx.addIssue({
                            path: [name, index, 'member'],
                            code: z.ZodIssueCode.custom,
                        });
                    }
                };

            filters.forEach(validateFilterRow('filters'));
        });

const validationSchema = (configSchema: ZodTypeAny) =>
    z.intersection(
        configSchema,
        z
            .object({
                id: z.string(),
                workspaceId: z.string(),
                filters: filterSchema.array(),
                title: z.string().min(1),
                dataset: z.string().min(1),
                visualization: z.custom<Target['visualization']>(),
                metric: z.string().min(1),
                target: z.number(),
                dateStart: z.date(),
                dateEnd: z.date(),
            })
            .refine(data => data.dateEnd > data.dateStart, {
                message: 'End date cannot be earlier than start date.',
                path: ['dateEnd'],
            })
    );

export function TargetForm({
    target,
    isNew,
}: {
    target: Target;
    isNew: boolean;
}) {
    const configSchema = filterConfigSchema(
        useQueryClient(),
        useActiveWorkspaceId()
    );
    const methods = useForm({
        defaultValues: target,
        resolver: zodResolver(validationSchema(configSchema)),
    });

    const dataset = methods.watch('dataset');

    const { data: dataModelSchema } = useDatasetSchema(dataset);

    const { update } = useTargetMutation();

    const { closeDialog, parameters } = useDialog<{ widgetId?: string }>(
        'target'
    );

    const submitHandler = methods.handleSubmit(async target =>
        update(target).then(() => {
            if (isNew) {
                eventBus.publish({
                    type: 'target_created',
                    id: target.id,
                    name: target.title,
                    widgetId: parameters?.widgetId,
                });
            }
            closeDialog();
        })
    );

    const modifiedTarget = methods.watch();

    if (!dataModelSchema) {
        return <Loader />;
    }

    return (
        <SeinoErrorBoundary>
            <FormProvider {...methods}>
                <Stack
                    component="form"
                    autoComplete="off"
                    onSubmit={submitHandler}
                    direction="row"
                    flex={1}
                >
                    <Stack
                        gap={2}
                        alignItems="center"
                        padding={2}
                        paddingTop={11}
                        sx={theme => ({ background: theme.elevation.base })}
                    >
                        <TargetCard
                            disableMenu
                            target={modifiedTarget}
                            isLoading={false}
                        />

                        <Controller
                            render={({ field }) => (
                                <ToggleButtonGroup
                                    size="small"
                                    aria-label="visualization"
                                    exclusive
                                    {...field}
                                >
                                    <ToggleButton value="line">
                                        Line
                                    </ToggleButton>
                                    <ToggleButton value="bar">Bar</ToggleButton>
                                </ToggleButtonGroup>
                            )}
                            name="visualization"
                            control={methods.control}
                        />
                    </Stack>
                    <Stack direction="column" padding={2} gap={2} flex={1}>
                        <SeinoDialogTitle>Target setup</SeinoDialogTitle>

                        <FormFields
                            originalTarget={target}
                            dataModelSchema={dataModelSchema}
                        />
                    </Stack>
                </Stack>
            </FormProvider>
        </SeinoErrorBoundary>
    );
}

const Section = ({
    label,
    children,
    fullWidth,
}: PropsWithChildren<{
    label?: string;
    fullWidth?: boolean;
}>) => (
    <Grid
        container
        padding={1}
        spacing={1}
        maxWidth={fullWidth ? undefined : '70%'}
    >
        {label && (
            <Grid item xs={12}>
                <Typography variant="smallMediumBody">{label}</Typography>
            </Grid>
        )}
        {children}
    </Grid>
);

function FormFields({
    originalTarget,
    dataModelSchema,
}: {
    originalTarget: Target;
    dataModelSchema: DataModelSchema;
}) {
    const { control, watch, formState, reset } = useFormContext<Target>();
    const { isUpdating } = useTargetMutation();

    const modifiedTarget = watch();

    const field = dataModelSchema?.fields?.find(
        f => f.id === modifiedTarget.metric
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack gap={1}>
                <Section>
                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            name="title"
                            render={({ field, fieldState }) => (
                                <TextField
                                    {...field}
                                    variant="filled"
                                    fullWidth
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    label="Title"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}></Grid>
                </Section>

                <Section>
                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            name="dateStart"
                            render={({ field, fieldState }) => (
                                <DatePicker
                                    {...field}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            variant: 'filled',
                                            error: !!fieldState.error,
                                            helperText:
                                                fieldState.error?.message,
                                        },
                                    }}
                                    label="Start date"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            name="dateEnd"
                            render={({ field, fieldState }) => (
                                <DatePicker
                                    {...field}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            variant: 'filled',
                                            error: !!fieldState.error,
                                            helperText:
                                                fieldState.error?.message,
                                        },
                                    }}
                                    label="End date"
                                />
                            )}
                        />
                    </Grid>
                </Section>

                <Section>
                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            name="dataset"
                            render={({ field: { ref, ...props } }) => (
                                <DatasetSelect
                                    {...props}
                                    labelId="dataset-label"
                                    label="Dataset"
                                    allowedDatasets={allowedDatasets}
                                    controlProps={{
                                        variant: 'filled',
                                        fullWidth: true,
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            name="metric"
                            render={({
                                field: { value, onChange },
                                fieldState: { error },
                            }) => (
                                <FormControl
                                    variant="filled"
                                    size="small"
                                    fullWidth
                                    error={!!error}
                                >
                                    <SchemaFieldSelect
                                        fullWidth
                                        schema={dataModelSchema}
                                        error={error}
                                        value={isString(value) ? value : null}
                                        onChange={value => onChange(value)}
                                        includeNone={false}
                                        textFieldProps={{
                                            label: 'Metric',
                                            variant: 'filled',
                                        }}
                                        fieldFilter={isTargetMetric}
                                    />
                                    <FormHelperText>
                                        {error?.message}
                                    </FormHelperText>
                                </FormControl>
                            )}
                        />
                    </Grid>
                </Section>

                <Section fullWidth>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="filters"
                            render={({
                                field: { value, onChange },
                                fieldState,
                            }) => (
                                <FilterBuilder
                                    dataset={modifiedTarget.dataset}
                                    schema={dataModelSchema}
                                    filters={value}
                                    onChange={onChange}
                                    variant="filled"
                                    fieldType="dimensions"
                                    addButtonText="Add filter"
                                    fieldState={fieldState}
                                />
                            )}
                        />
                    </Grid>
                </Section>

                <Section>
                    <Grid item xs={6}>
                        <FormControl variant="filled" size="small" fullWidth>
                            <InputLabel id="dataset-label" shrink>
                                Target
                            </InputLabel>
                            <Controller
                                control={control}
                                name="target"
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        InputProps={{
                                            inputComponent:
                                                NumericInputFormatter as any,
                                            startAdornment: (
                                                <FormatAdornment
                                                    position="start"
                                                    format={field?.format}
                                                />
                                            ),
                                            endAdornment:
                                                field &&
                                                field?.agg?.type !==
                                                    'weighted_avg' ? (
                                                    <Calculator
                                                        target={modifiedTarget}
                                                        dataModelField={field}
                                                    />
                                                ) : undefined,
                                        }}
                                        value={asRenderedValue(field, value)}
                                        error={!!error}
                                        onChange={({ target: { value } }) => {
                                            const newValue = parseFloat(value);

                                            onChange(
                                                asStoredValue(field, newValue)
                                            );
                                        }}
                                        variant="filled"
                                        fullWidth
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Section>

                <Section>
                    <Grid item xs={12}>
                        <LoadingButton
                            loading={isUpdating}
                            type="submit"
                            size="small"
                        >
                            Save
                        </LoadingButton>
                        {formState.isDirty && (
                            <Button
                                hidden={!formState.isDirty}
                                type="reset"
                                size="small"
                                onClick={e => {
                                    e.preventDefault();
                                    reset(originalTarget);
                                }}
                            >
                                Reset
                            </Button>
                        )}
                    </Grid>
                </Section>
            </Stack>
        </LocalizationProvider>
    );
}
