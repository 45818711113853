import { useDuckQuery } from './use-duck-query';
import { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { useDuckDataset } from './use-duck-dataset';
import { DataModelSchema } from '../types/datamodel/schema';
import { identity } from 'lodash';
import { useMemo } from 'react';
import { findDateField } from '../queries/schema.ts';

export type DuckDatasetQuery = (
    sourceTable: string,
    dateField: string,
    schema: DataModelSchema
) => string;

type UseDuckDatasetQueryProps<TRow, TResult = TRow[]> = {
    queryKey: QueryKey;
    dataset: string;
    dateField?: string | null;
    queryFn: DuckDatasetQuery;
    queryOptions?: UseQueryOptions<TResult>;
    processResult?: (rows: TRow[]) => TResult;
};

export const useDuckDatasetQuery = <TRow, TResult = TRow[]>({
    queryKey,
    dataset,
    dateField,
    queryFn,
    queryOptions = {},
    processResult = identity,
}: UseDuckDatasetQueryProps<TRow, TResult>) => {
    const {
        isLoading: isDatasetLoading,
        error: datasetError,
        data: datasetMetadata,
    } = useDuckDataset(dataset);

    const disabled = queryOptions.enabled === false;

    const { data, isLoading, error, isError } = useDuckQuery(
        queryKey,
        async duck => {
            if (datasetError) {
                throw datasetError;
            }

            if (!datasetMetadata) {
                throw new Error(
                    'query executing while still waiting for dataset'
                );
            }

            const { schema, tableId } = datasetMetadata;
            const dateFieldChecked = findDateField(schema, dateField);

            return await duck.connection.query(
                queryFn(tableId, dateFieldChecked, schema!)
            );
        },
        {
            ...queryOptions,
            staleTime: 60 * 60_0000,
            enabled: !disabled && !isDatasetLoading,
        },
        processResult
    );

    return useMemo(
        () => ({
            data,
            isLoading,
            isError,
            error,
            isDatasetLoading,
            datasetError,
            schema: datasetMetadata?.schema,
        }),
        [
            data,
            error,
            isLoading,
            isError,
            datasetError,
            datasetMetadata?.schema,
            isDatasetLoading,
        ]
    );
};
