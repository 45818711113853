import { Stack, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { DateFieldSelect } from './DateFieldSelect.tsx';
import * as React from 'react';
import { useCallback } from 'react';
import Button from '@mui/material/Button';
import { useExplorerState } from '../../hooks/use-explorer-state.ts';
import {
    DataModelSchema,
    isDateDimension,
} from '../../types/datamodel/schema.ts';
import { useExplorerViews } from '../../hooks/use-explorer-views.ts';
import { useActiveWorkspaceId } from '../../providers/useActiveWorkspaceId.ts';
import { LoadingButton } from '@mui/lab';
import { useDatasetSchema } from '../../hooks/use-dataset-schema.ts';

type ExplorerFormProps = {
    schema: DataModelSchema;
    onClose: () => void;
};

export const ExplorerForm = (props: Omit<ExplorerFormProps, 'schema'>) => {
    const { view } = useExplorerState();
    const { data: dataset } = useDatasetSchema(view.config.dataset);
    if (!dataset) {
        return null;
    }

    return <ExplorerFormInner schema={dataset} {...props} />;
};

const ExplorerFormInner = ({ schema, onClose }: ExplorerFormProps) => {
    const { view } = useExplorerState();
    const { updateView, isLoading } = useExplorerViews(useActiveWorkspaceId());

    const defaultValues = {
        title: view.title,
        dateField: view.config.dateField || defaultDateField(schema),
    };

    const {
        register,
        control,
        reset,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues,
    });

    const onSubmit = handleSubmit(async ({ title, dateField }) => {
        await updateView(view.id, { ...view.config, dateField }, title);
        onClose();
    });

    const onCancel = useCallback(() => {
        onClose();
        reset();
    }, [onClose, reset]);

    return (
        <form onSubmit={onSubmit}>
            <Stack gap={2}>
                <TextField
                    {...register('title', { minLength: 3 })}
                    label="Title"
                    error={!!errors.title}
                />
                {schema && (
                    <Controller
                        control={control}
                        name="dateField"
                        render={({ field: { ref, ...props } }) => (
                            <DateFieldSelect
                                dataset={
                                    view.config.dataset ??
                                    'campaign_metrics_by_send_date'
                                }
                                schema={schema}
                                {...props}
                            />
                        )}
                    />
                )}
                <Stack direction="row" justifyContent="flex-end">
                    <Stack gap={2} direction="row">
                        <Button size="small" onClick={onCancel}>
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            size="small"
                            loading={isLoading}
                        >
                            Save
                        </LoadingButton>
                    </Stack>
                </Stack>
            </Stack>
        </form>
    );
};

const defaultDateField = (schema?: DataModelSchema) => {
    if (!schema) {
        return undefined;
    }

    const dateFields = schema.fields.filter(isDateDimension);
    if (dateFields.length < 2) {
        return undefined;
    }

    return schema.dateField;
};
