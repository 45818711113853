import {
    FormControl,
    FormControlProps,
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
} from '@mui/material';
import React from 'react';
import { useDatasets } from '../../hooks/use-datasets';
import _ from 'lodash';

type DatasetSelectProps = SelectProps<string> & {
    allowedDatasets: string[];
    controlProps?: FormControlProps;
};

export const DatasetSelect = ({
    allowedDatasets,
    controlProps,
    ...props
}: DatasetSelectProps) => {
    const { data: availableDatasets, isLoading: datasetsLoading } =
        useDatasets();

    const allowAll = allowedDatasets.length === 0;
    const options = _.chain(availableDatasets || [])
        .filter(({ id }) => allowAll || allowedDatasets.includes(id))
        .map(({ id, title }) => ({ label: title || id, value: id }))
        .sortBy('label')
        .value();

    if (datasetsLoading) {
        return <></>;
    }

    return (
        <FormControl {...controlProps}>
            <InputLabel>Dataset</InputLabel>
            <Select size="small" {...props} label="Dataset">
                {options.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
