import React from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
} from '@mui/material';
import {
    DataModelSchema,
    notHasFeature,
} from '../../types/datamodel/schema.ts';

type DateFieldSelectProps = {
    dataset: string;
    schema: DataModelSchema;
} & SelectProps<string | null>;

export const DateFieldSelect = ({
    dataset,
    schema,
    ...props
}: DateFieldSelectProps) => {
    const dateFields = (schema?.fields || [])
        .filter(f => f.format === 'date')
        .filter(notHasFeature('hide'));

    if (dateFields.length < 2 || dataset === 'campaign_metrics_by_event_date') {
        return null;
    }

    return (
        <FormControl>
            <InputLabel id="dataset-date-field-label">
                Selected period applies to
            </InputLabel>
            <Select
                labelId="dataset-date-field-label"
                label="Selected period applies to"
                {...props}
            >
                {dateFields.map(f => (
                    <MenuItem key={f.id} value={f.id}>
                        {f.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
