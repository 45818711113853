import {
    calculateGranularity,
    TimeSeriesQuery,
} from '../../../../queries/time-series';
import { BinaryFilter } from '../../../../queries/filters';
import { useActiveWorkspace } from '../../../../providers/WorkspaceProvider.hooks.ts';
import { Md5 } from 'ts-md5';
import { useDuckDatasetQuery } from '../../../../hooks/use-duck-dataset-query.ts';
import { useDateRange } from '../../../../hooks/use-date-range.ts';
import {
    DataModelField,
    DataModelSchema,
} from '../../../../types/datamodel/schema.ts';
import { match } from 'ts-pattern';
import { dateFormat } from '../../../../utils/date-format.ts';
import { findField } from '../../../../queries/schema.ts';
import { indent } from '../../../../queries/indent.ts';
import { sqlForMetrics } from '../../../../queries/metrics-sql.ts';
import { sqlForFilters } from '../../../../queries/filters-sql.ts';

export const useGraphWidgetData = (
    dataset: string,
    dateField: string | null | undefined,
    metrics: DataModelField[],
    dimension: DataModelField | undefined,
    filters: BinaryFilter[]
) => {
    const { workspaceId } = useActiveWorkspace();
    const { dateRanges } = useDateRange();
    const granularity = calculateGranularity(dateRanges.main);

    const query: TimeSeriesQuery = {
        dateRange: dateRanges.main,
        dateField,
        granularity,
        filters,
        metrics: metrics.map(m => m.id),
        dimension: dimension?.id,
    };

    const queryKey = Md5.hashStr(JSON.stringify({ dataset, ...query }));

    return useDuckDatasetQuery({
        queryKey: ['duckdb', workspaceId, 'time-series-group', queryKey],
        dataset,
        dateField,
        queryFn: (sourceTable, dateField, schema) =>
            sqlForTimeSeriesQueryGroupBy(schema, dateField, sourceTable, query),

        processResult: rows => {
            return Object.fromEntries(
                rows.map((row: any) => [
                    row.metric,
                    row.series
                        .toArray()
                        // Use the metric name as serie id when groupBy applied
                        .map(formatSeries(!dimension && row.metric)),
                ])
            );
        },
        queryOptions: {
            enabled: metrics.length > 0,
        },
    });
};

const formatSeries = (metric: string | undefined) => {
    return ({ dimension, data }: any) => {
        return {
            id: metric || dimension,
            data: data.toArray(),
        };
    };
};

function sqlForTimeSeriesQueryGroupBy(
    schema: DataModelSchema,
    dateField: string,
    sourceTable: string,
    query: TimeSeriesQuery
) {
    const granularity = match(query.granularity)
        .with('day', 'month', 'quarter', 'week', granularity => granularity)
        .with(undefined, () => 'millenium')
        .exhaustive();

    const start = dateFormat(query.dateRange.start);
    const end = dateFormat(query.dateRange.end);

    const dimensionField = findField(schema)(query.dimension);
    const dimension = dimensionField?.sql || dimensionField?.id || 'null';

    return `
  with y as (
         select date_trunc('${granularity}', ${schema.dateField}) as x,
                ${dimension}::string as dimension,
${indent(sqlForMetrics(schema, query.metrics), 16)}
           from ${sourceTable}
          where ${dateField} between date '${start}' and date '${end}'
${indent(sqlForFilters(schema, query.filters), 16)}
          group by *
       )

     , x as (
         select date_trunc('${granularity}', d) as x,
                dimension,
                cast(min(d) as date)::string as start,
                cast(max(d) as date)::string as end,
           from (select unnest(generate_series(date '${start}', date '${end}', interval 1 day)) as d)
           left join (select distinct dimension::string as dimension from y) on true
          group by *
       )

     , xy as (
         select coalesce(x.dimension, '(empty)') as dimension,
                x.start as x,
                x.end as xEnd,
${indent(query.metrics.join(',\n'), 16)}
          from x
          left join y
            on x.x = y.x
           and x.dimension is not distinct from y.dimension
       )
     , xy_unpivot as (
          from xy unpivot include nulls
               (y for metric in (${query.metrics}))
     )

     , xy_agg as (
         select metric,
                dimension,
                array_agg(struct_pack(x, y, xEnd) order by x) as data,
                coalesce(sum(y), 0) as sort,
           from xy_unpivot
          group by *
          order by metric, sort desc
       )

select metric,
       list_slice(array_agg(struct_pack(dimension, data, sort)), 1, 10) as series
  from xy_agg
 group by metric
`;
}
