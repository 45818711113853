import { useCallback } from 'react';
import { useDatasetSchema } from '../../../../hooks/use-dataset-schema';
import { updateWidget, WidgetConfig } from '../../../../types/dashboard';
import { Widget } from '../Widget';
import { TableChart, TableChartGridConfig } from './Chart/TableChart';
import { useTableQueryDuck } from './useTableQueryDuck';
import { uniq } from 'lodash';
import { useDateRange } from '../../../../hooks/use-date-range';
import { useDashboardState } from '../../../../pages/Dashboard/useDashboardState.ts';

export interface TableWidgetConfig extends WidgetConfig {
    data: TableChartGridConfig;
    isEditable: boolean;
}

export const TableWidget = (config: TableWidgetConfig) => {
    const { dateRanges } = useDateRange();

    const { localDashboard, saveLocal } = useDashboardState();

    const applyChange = useCallback(
        (gridConfig: Partial<WidgetConfig>) => {
            const payload = {
                ...config,
                ...gridConfig,
            };

            if (config.isEditable) {
                saveLocal(updateWidget(localDashboard, payload));
            }
        },
        [config, saveLocal, localDashboard]
    );

    const { data: schema = { fields: [], dateField: '' } } = useDatasetSchema(
        config.dataset
    );

    const {
        metricData,
        totalRowData,
        isLoading: queryLoading,
    } = useTableQueryDuck({
        dataset: config.dataset,
        dateField: config.dateField,
        dateRange: dateRanges.main,
        compareDateRange: dateRanges.compare,
        metrics: config.metrics,
        filters: config.filters || [],
        dimensions: uniq([
            ...(config.dimensions || []),
            ...(config.groupBy || []),
        ]),
    });

    return (
        <Widget config={config}>
            <TableChart
                key={JSON.stringify([
                    config.dimensions,
                    config.groupBy,
                    config.metrics,
                    dateRanges.compare.preset !== 'disabled',
                ])}
                isLoading={queryLoading}
                data={metricData}
                totalRowData={totalRowData}
                dimensions={config.dimensions || []}
                groupBy={config.groupBy || []}
                metrics={config.metrics || []}
                schema={schema}
                gridConfig={config.data}
                onConfigUpdated={applyChange}
                showCompare={dateRanges.compare.preset !== 'disabled'}
            />
        </Widget>
    );
};
