import { UseQueryOptions } from '@tanstack/react-query';
import { Md5 } from 'ts-md5';
import { useDuckDatasetQuery } from '../hooks/use-duck-dataset-query';
import { TableCompareQuery } from './table-compare';
import { sqlForTableCompareQuery } from './table-compare-sql';
import { useActiveWorkspace } from '../providers/WorkspaceProvider.hooks';

export const useTableCompareQuery = <TResult>(
    dataset: string,
    query: TableCompareQuery,
    queryOptions?: UseQueryOptions<TResult>
) => {
    const { workspaceId } = useActiveWorkspace();
    const queryKey = Md5.hashStr(JSON.stringify({ dataset, ...query }));

    return useDuckDatasetQuery({
        queryKey: ['duckdb', workspaceId, 'table-compare', queryKey],
        dataset,
        dateField: query.dateField,
        queryFn: (sourceTable, dateField, schema) =>
            sqlForTableCompareQuery(schema, dateField, sourceTable, query),
        queryOptions,
    });
};
