import { BinaryFilter } from '../../../../queries/filters';
import { useTableCompareQuery } from '../../../../queries/table-compare-hook';

interface UseTableQueryProps {
    dataset: string;
    dateField: string | null | undefined;
    dateRange: Interval;
    compareDateRange: Interval;
    metrics: string[];
    filters: BinaryFilter[];
    dimensions: string[];
}

export const useTableQueryDuck = ({
    dataset,
    dateField,
    dateRange,
    compareDateRange,
    metrics,
    filters,
    dimensions,
}: UseTableQueryProps) => {
    const tableResult = useTableCompareQuery(dataset, {
        dimensions,
        metrics,
        filters,
        dateField,
        dateRange,
        compareDateRange,
    });

    const totalsResult = useTableCompareQuery(dataset, {
        dimensions: [],
        metrics,
        filters,
        dateField,
        dateRange,
        compareDateRange,
    });

    return {
        isLoading: tableResult.isLoading || totalsResult.isLoading,
        error: tableResult.isError || totalsResult.isError,
        metricData: tableResult.data,
        totalRowData: totalsResult.data,
    };
};
