import { Skeleton } from '@mui/material';
import { captureException } from '@sentry/react';
import { useDatasetSchema } from '../../../../hooks/use-dataset-schema.ts';
import { findField, isField } from '../../../../queries/schema.ts';
import { WidgetConfig } from '../../../../types/dashboard';
import { PerformanceCardGrid } from '../CampaignPerformance/CampaignPerformanceWidget.styled.tsx';
import { Widget } from '../Widget';
import { WidgetError } from '../WidgetError.tsx';
import { useGraphWidgetData } from './use-graph-widget-data.ts';
import { LineChart } from './LineChart.tsx';

export const GraphWidget = (config: WidgetConfig) => {
    const { data: schema } = useDatasetSchema(config.dataset);
    const metrics = config.metrics.map(findField(schema)).filter(isField);
    const groupBy = findField(schema)(config.dimension);

    const { isLoading, data, error } = useGraphWidgetData(
        config.dataset,
        config.dateField,
        metrics,
        groupBy,
        config.filters || []
    );

    if (error) {
        captureException(error);
        return (
            <Widget
                config={{
                    ...config,
                    type: 'graph',
                    modified: config?.modified || new Date().getTime(),
                }}
            >
                <WidgetError />
            </Widget>
        );
    }

    return (
        <Widget config={config}>
            <PerformanceCardGrid minItemWidth={500}>
                {metrics.map((metric, i) => {
                    if (isLoading) {
                        return <Skeleton height={300} key={i} />;
                    }

                    if (!data[metric.id]) {
                        captureException(
                            `Missing data for ${metric.id} in widget ${config.id}`
                        );
                        return null;
                    }

                    return (
                        <LineChart
                            key={i}
                            metric={metric}
                            groupBy={groupBy}
                            series={data[metric.id]}
                        />
                    );
                })}
            </PerformanceCardGrid>
        </Widget>
    );
};
