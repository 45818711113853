import {
    DataModelField,
    isGroupedDimension,
} from '../types/datamodel/schema.ts';
import { DuckDataset } from '../hooks/use-duck-dataset.ts';
import { Filter } from './filters.ts';
import { dateFormat } from '../utils/date-format.ts';
import { isDefined } from '../utils/is-defined.ts';
import { sqlForFilter } from './filters-sql.ts';
import { fieldSql, SqlContext } from './field-sql.ts';
import { findField, includeSourceMetrics } from './schema.ts';
import { uniqBy } from 'lodash';

export type Cte = {
    sql: string;
    name: string;
};

export type OrderBy = [string, 'asc' | 'desc'];

export class QueryBuilder {
    private ctes: Cte[] = [];
    private selectedFields: DataModelField[] = [];
    private selectExprs: string[] = [];
    private fromExpr: string;
    private whereFilters: Filter[] = [];
    private havingFilters: Filter[] = [];
    private sorts: OrderBy[] = [];
    private dateRange?: Interval;

    constructor(
        private readonly dataset: DuckDataset,
        private readonly dateField: string,
        private readonly context: SqlContext
    ) {
        this.dateField = dateField;
        this.fromExpr = this.dataset.tableId;
    }

    with(cte: Cte) {
        this.ctes.push(cte);
        return this;
    }

    selectFields(fields: DataModelField[]) {
        this.selectedFields = uniqBy(
            [
                ...this.selectedFields,
                ...fields,
                ...fields.flatMap(
                    includeSourceMetrics(findField(this.dataset.schema))
                ),
            ],
            'id'
        );

        return this;
    }

    selectId() {
        const groupBy = this.selectedFields.filter(isGroupedDimension);
        this.selectExprs.push(
            groupBy.length > 0
                ? `concat_ws(';', ${groupBy
                      .map(
                          f =>
                              `ifnull((${fieldSql(f, {
                                  isProjection: false,
                              })})::string, '(null)')`
                      )
                      .join(', ')}) as __id`
                : `'root' as __id`
        );
        return this;
    }

    select(exprs: string[]) {
        this.selectExprs.push(...exprs);
        return this;
    }

    from(expr: string) {
        this.fromExpr = expr;
        return this;
    }

    where(filters: Filter[]) {
        this.whereFilters.push(...filters);
        return this;
    }

    whereDateRange(dateRange: Interval) {
        this.dateRange = dateRange;
        return this;
    }

    having(having: Filter[]) {
        this.havingFilters.push(...having);
        return this;
    }

    orderBy(sorts: OrderBy[]) {
        this.sorts.push(...sorts);
        return this;
    }

    toSql() {
        return `
 ${this.cteSql()}
 ${this.selectSql()}
 ${this.fromSql()}
 ${this.whereSql()}
 ${this.groupBySql()}
 ${this.havingSql()}
 ${this.orderBySql()}`;
    }

    private cteSql() {
        return this.ctes.length > 0
            ? `with ${this.ctes
                  .map(({ sql, name }) => `${name} as (${sql})`)
                  .join(',\n')}`
            : '';
    }

    private selectSql() {
        const projections = this.selectedFields
            .map(f => fieldSql(f, { ...this.context, isProjection: true }))
            .filter(isDefined)
            .concat(this.selectExprs)
            .map(f => `${f},\n`);

        return `select ${projections.join('')}`;
    }

    private fromSql() {
        return `from ${this.fromExpr}`;
    }

    private whereSql() {
        const and = [...this.dateRangeFilter(), ...this.whereFilters];
        return and.length > 0 ? `where ${sqlForFilter({ and })}` : '';
    }

    private groupBySql() {
        const groupBy = this.selectedFields.filter(isGroupedDimension);

        return groupBy.length > 0
            ? `group by ${groupBy
                  .map(f =>
                      fieldSql(f, { ...this.context, isProjection: false })
                  )
                  .join(', ')}`
            : '';
    }

    private havingSql() {
        return this.havingFilters.length > 0
            ? `having ${sqlForFilter({ and: this.havingFilters })}`
            : '';
    }

    private orderBySql() {
        return this.sorts.length > 0
            ? `order by ${this.sorts.map(s => s.join(' ')).join(',')}`
            : '';
    }

    private dateRangeFilter(): Filter[] {
        return this.dateRange
            ? [
                  {
                      operator: 'inRange',
                      member: this.dateField,
                      values: [
                          dateFormat(this.dateRange.start),
                          dateFormat(this.dateRange.end),
                      ],
                  },
              ]
            : [];
    }
}
